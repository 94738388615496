import {
  AlertColor,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { boxCardNP, boxPagesContent } from "../../theme/ui/Boxs";
import IconDoc from "../../assets/icons/fileIcon.svg";
import {
  greenBigTitle,
  lineSubText,
} from "../../components/ui-components/Typography/typographyStyles";
import FiltroIcon from "../../assets/icons/filtroICon.svg";
import GrayFiltre from "../../assets/icons/grayFiltre.svg";
import { NewTracingTable } from "./components/newTracingTable";
import { ModalFiltroTracing } from "./components/modalFiltroTracing";
import ButtonEdit from "./components/buttonEdit";
import { ChipsTracing } from "./components/chipsTracing";
import { useState } from "react";
import useNewTracingCM from "./hooks/useNewTracingCM";
import SnackBar, {
  SnackBarState,
} from "../../components/ui-components/Snackbar/Snackbar";
import { LoadingButton } from "../../components/ui-components/Buttons/LoadingButton";
import ResumeAccounts from "./components/resumeAccounts";
import QuickSearch from "./components/quickSearch/quickSearch";
import { MainLoadTracing } from "./components/tracingLoaders";
import { ModalHistory } from "./components/resolutiveHistory/modalHistory";
import { NetworkStatus } from "@apollo/client";
import useFileHandler from "./hooks/useFileHandler";
import useSend from "./hooks/useSend";

export const NewTracingCM = () => {
  const [snackData, setSnackData] = useState<SnackBarState>({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const showMessage = (message: string, severity: AlertColor) => {
    setSnackData({
      abrir: true,
      mensaje: message,
      severidad: severity,
    });
  };
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [currentMenuRow, setCurrentMenuRow] = useState({});
  const {
    count,
    error,
    isEmptyState,
    isLoading,
    isLoadingExport,
    orderByCol,
    orderDirection,
    pageAccount,
    records,
    rowsAccount,
    resumeAccounts,
    selectedRecords,
    selectedRecordsType,
    filterData,
    networkStatus,
    getRecords,
    isSelected,
    handleExportRecords,
    handleItemChecked,
    handleSelectAllClick,
    onRequestSort,
    refetch,
    resetSelectedRecords,
    setPageAccount,
    setRowsAccount,
  } = useNewTracingCM({ showMessage });
  const { loadingObtenerDocumento, handleObtenerDocumentos } = useFileHandler({
    showMessage,
  });
  const { loadingExecMigration, sendingQueue, handleSend, setSendingQueue } = useSend({ refetch, showMessage });

  const handleBulkDownload = (type: string) => {
    const ids = records
      .filter((record: any) =>
        selectedRecords.includes(
          `${record.type === 2 ? record.id : record.idCMD ?? record.id}-${record.canalOrigen
          }`
        )
      )
      .map((record: any) => record.id);
    handleObtenerDocumentos(ids, type, resetSelectedRecords);
  };

  const handleBulkSend = () => {
    const ids: string[] = records
      .filter((record: any) =>
        selectedRecords.includes(`${record.idCMD}-${record.canalOrigen}`)
      )
      .map((record: any) => `${record.idCMD}-${record.canalOrigen}`);
    setSendingQueue([...sendingQueue, ...selectedRecords]);
    handleSend(ids, resetSelectedRecords);
  };

  return (
    <>
      <Box pt="30px" sx={boxPagesContent}>
        <ModalHistory
          open={showHistoryModal}
          onClose={() => setShowHistoryModal(!showHistoryModal)}
          row={currentMenuRow}
        />
        <ModalFiltroTracing
          getRecords={getRecords}
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(!showFilterModal)}
          filterData={filterData}
        />
        <SnackBar state={snackData} setState={setSnackData} vertical="top" />
        {selectedRecords.length > 0 && (
          <ButtonEdit
            isLoading={loadingObtenerDocumento || sendingQueue.length > 0 || loadingExecMigration}
            handleBulkSend={handleBulkSend}
            handleBulkDownload={handleBulkDownload}
            checkCount={selectedRecords.length}
            selectedRecordsType={selectedRecordsType}
          />
        )}
        <Box sx={boxCardNP} p="24px">
          <Grid container>
            <Grid item xs={1}>
              <img src={IconDoc} alt="" />
            </Grid>
            <Grid item xs={11} pl="44px" alignSelf={"center"}>
              <Typography sx={greenBigTitle}>
                Seguimiento y envío de Cuentas Médicas
              </Typography>
              <Typography sx={lineSubText}>
                Aquí podrás buscar información de cuentas y usar filtros para
                resultados más específicos.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {isLoading &&
          !(
            networkStatus === NetworkStatus.poll ||
            networkStatus === NetworkStatus.ready
          ) ? (
          <MainLoadTracing rowsAccount={rowsAccount} />
        ) : (
          <>
            <Box sx={boxCardNP} mt="32px !important">
              {resumeAccounts && (
                <ResumeAccounts resumeAccounts={resumeAccounts ?? {}} />
              )}
              <Divider />
              <Stack direction={"row"} p="18px 22px">
                <QuickSearch
                  getRecords={getRecords}
                  filterData={filterData}
                />
                <Stack direction={"row"} pt="26px" spacing={"16px"}>
                  <Button
                    disabled={isEmptyState || error}
                    variant="outlined"
                    onClick={() => {
                      setShowFilterModal(!showFilterModal);
                      resetSelectedRecords();
                    }}
                    startIcon={
                      <img
                        src={isEmptyState || error ? GrayFiltre : FiltroIcon}
                        alt=""
                      />
                    }
                    data-testid="button-modal-filter"
                  >
                    Filtro
                  </Button>
                  <LoadingButton
                    sx={{ minWidth: "130px" }}
                    toDo={handleExportRecords}
                    disabled={isEmptyState || error || records.length === 0}
                    loading={isLoadingExport}
                  >
                    Exportar
                  </LoadingButton>
                </Stack>
              </Stack>
              <Grid container p={Object.keys(filterData).length > 0 ? "22px" : "0px"}>
                <ChipsTracing
                  getRecords={getRecords}
                  filterData={filterData}
                />
              </Grid>
              <NewTracingTable
                refetch={refetch}
                resetSelectedRecords={resetSelectedRecords}
                loadingObtenerDocumento={loadingObtenerDocumento}
                loadingExecMigration={loadingExecMigration}
                count={count}
                error={error}
                rows={records}
                rowsAccount={rowsAccount}
                isEmptyState={isEmptyState}
                orderBy={orderByCol}
                orderDirection={orderDirection}
                pageAccount={pageAccount}
                selectedRecords={selectedRecords}
                sendingQueue={sendingQueue}
                handleSelectAllClick={handleSelectAllClick}
                isSelected={isSelected}
                handleItemChecked={handleItemChecked}
                onRequestSort={onRequestSort}
                setPageAccount={setPageAccount}
                setRowsAccount={setRowsAccount}
                setCurrentMenuRow={setCurrentMenuRow}
                setShowHistoryModal={setShowHistoryModal}
                showMessage={showMessage}
                setSendingQueue={setSendingQueue}
                handleSend={handleSend}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
