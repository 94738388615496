import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/cruzChips.svg";
import {filterArgs } from "../types";

type ChipsTracingProps = {
  filterData: filterArgs;
  getRecords: Function;
};

export function ChipsTracing({
  filterData,
  getRecords,
}: ChipsTracingProps) {
  const clearFilters = () => {
    const filters: filterArgs = {};
    getRecords(filters);
  };
  
  const handleDelete = (filterIndex: number) => {
    const chipFilter = {...filterData};
    switch (filterIndex) {
      case 1: {
        delete chipFilter.id;
        break;
      }
      case 2: {
        delete chipFilter.idCMD;
        break;
      }
      case 3: {
        delete chipFilter.numeroCuenta;
        break;
      }
      case 4: {
        delete chipFilter.numeroCobro;
        break;
      }
      case 5: {
        delete chipFilter.rutPaciente;
        break;
      }
      case 6: {
        delete chipFilter.rutPrestador;
        break;
      }
      case 7: {
        delete chipFilter.montoTotal;
        break;
      }
      case 8: {
        delete chipFilter.estado;
        break;
      }
      case 9: {
        delete chipFilter.fechaEntrega;
        break;
      }
      case 10: {
        delete chipFilter.fechaRecepcion;
        break;
      }
      case 11: {
        delete chipFilter.canalOrigen;
        break;
      }
      case 12: {
        delete chipFilter.numeroEnvio;
        break;
      }
      case 13: {
        delete chipFilter.fechaEmision;
        break;
      }
      case 14: {
        delete chipFilter.fechaDevuelta;
        break;
      }
      default:
        return;
    }
    getRecords(chipFilter);
  };

  const showChips =
    filterData.id ||
    filterData.idCMD ||
    filterData.numeroCuenta ||
    filterData.numeroEnvio ||
    filterData.numeroCobro ||
    filterData.rutPaciente ||
    filterData.rutPrestador ||
    filterData.montoTotal ||
    filterData.estado ||
    filterData.fechaEntrega ||
    filterData.fechaRecepcion ||
    filterData.fechaEmision ||
    filterData.fechaDevuelta ||
    filterData.canalOrigen;

  return (
    <>
      {showChips && (
        <>
          <Grid item xs={12} pt="15px">
            <label className="filtroFont">Resultados filtrados por: </label>{" "}
          </Grid>
          <Grid item xs={12} pt="4px">
            {filterData.fechaEntrega && (
              <Chip
                sx={{ mr: "16px" }}
                label="Fecha de entrega"
                variant="outlined"
                onDelete={() => handleDelete(9)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-fechaEntrega"
                  />
                }
              />
            )}
            {filterData.estado && (
              <Chip
                sx={{ mr: "16px" }}
                label="Estado"
                variant="outlined"
                onDelete={() => handleDelete(8)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-estado" />
                }
              />
            )}
            {filterData.numeroCuenta && (
              <Chip
                sx={{ mr: "16px" }}
                label="Nº C Prestador"
                variant="outlined"
                onDelete={() => handleDelete(3)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-numeroCuenta"
                  />
                }
              />
            )}
            {filterData.id && (
              <Chip
                sx={{ mr: "16px" }}
                label="Nº Cuenta"
                variant="outlined"
                onDelete={() => handleDelete(1)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-id" />
                }
              />
            )}
            {filterData.idCMD && (
              <Chip
                sx={{ mr: "16px" }}
                label="Nº C. Extranet"
                variant="outlined"
                onDelete={() => handleDelete(2)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-idCMD" />
                }
              />
            )}
            {filterData.numeroEnvio && (
              <Chip
                sx={{ mr: "16px" }}
                label="Nº Envío"
                variant="outlined"
                onDelete={() => handleDelete(12)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-numeroEnvio"
                  />
                }
              />
            )}
            {filterData.numeroCobro && (
              <Chip
                sx={{ mr: "16px" }}
                label="Nº Cobro"
                variant="outlined"
                onDelete={() => handleDelete(4)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-numeroCobro"
                  />
                }
              />
            )}
            {filterData.rutPrestador && (
              <Chip
                sx={{ mr: "16px" }}
                label="Prestador"
                variant="outlined"
                onDelete={() => handleDelete(6)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rutPrestador"
                  />
                }
              />
            )}
            {filterData.canalOrigen && (
              <Chip
                sx={{ mr: "16px" }}
                label="Canal"
                variant="outlined"
                onDelete={() => handleDelete(11)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-canalOrigen"
                  />
                }
              />
            )}
            {filterData.rutPaciente && (
              <Chip
                sx={{ mr: "16px" }}
                label="Beneficiario"
                variant="outlined"
                onDelete={() => handleDelete(5)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rutPaciente"
                  />
                }
              />
            )}
            {filterData.montoTotal && (
              <Chip
                sx={{ mr: "16px" }}
                label="Total Facturado"
                variant="outlined"
                onDelete={() => handleDelete(7)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-montoTotal"
                  />
                }
              />
            )}
            {filterData.fechaRecepcion && (
              <Chip
                sx={{ mr: "16px" }}
                label="Fecha de entrega"
                variant="outlined"
                onDelete={() => handleDelete(10)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-fechaRecepcion"
                  />
                }
              />
            )}
            {filterData.fechaEmision && (
              <Chip
                sx={{ mr: "16px" }}
                label="Fecha de liquidación"
                variant="outlined"
                onDelete={() => handleDelete(13)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-fechaEmision"
                  />
                }
              />
            )}
            {filterData.fechaDevuelta && (
              <Chip
                sx={{ mr: "16px" }}
                label="Fecha de Devolución"
                variant="outlined"
                onDelete={() => handleDelete(14)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-fechaDevuelta"
                  />
                }
              />
            )}
            <Button
              color="inherit"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "from-font",
                pl: "12px",
              }}
              data-testid="cleanFilters"
              onClick={clearFilters}
            >
              Limpiar filtros
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}
