import { gql } from "@apollo/client";

export const getRenditionByTributaryDocument = gql`
query renditionsByFilters($renditionFilters: renditionFiltersDoc, $page: Int, $perPage: Int, $orderBy: String) {
    renditionsByFilters(renditionFilters: $renditionFilters,  page: $page, perPage: $perPage, orderBy: $orderBy) {
        estado
        mensaje
        totalRecords
        countRenditions
        data {
            id
            rutProvider
            renditionStatus
            dateState
            documentFolio
            documentNet
            documentAmount
            totalRendition
        }
    }
}`;