import { Box, Grid, Typography } from "@mui/material";
import {
  backGreen,
  grayText,
  titleBox,
} from "../ui-components/Typography/typographyStyles";
import { truncateText } from "../../utils/truncateText";
import { formatNumber } from "../../utils/formatNumber";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { useContext } from "react";

export const RenditionDetails = () => {
  const { dataContext } = useContext<ContextValue>(ContextRendition);
  const { totalMedicalBonds, amountToPay, file } = dataContext;

  return (
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, p: 1, backgroundColor: '#E6F6F5' }}>
        <Grid item xs={3}>
          <Typography sx={grayText}>
            Total de bonos cargados: {totalMedicalBonds > 0 && totalMedicalBonds}
          </Typography>
          </Grid>
        <Grid item xs={6}>
          <Typography sx={grayText}>
            Nombre del archivo: <small>{file && truncateText(file?.name, 40)}</small>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={backGreen}>
            Monto total por rendir: {amountToPay > 0 && formatNumber(Number(amountToPay))}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  
  );
};
