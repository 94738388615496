import { boxCard } from "../../theme/ui/Boxs";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { SelectProvider } from './selectProvider';
import { RenditionDetails } from "./renditionDetails";
import { SendFile } from "./sendFile";

function BonusHeader() {
  return (
    <Box sx={boxCard} mt="18px !important">       
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectProvider />
        </Grid>
        <Grid item xs={12} md={8} display="flex" justifyContent="center" alignItems="center">
          <SendFile />
        </Grid>
        <Grid item xs={12} mt={2}>
          <RenditionDetails />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BonusHeader;