  export const defaultPrestador = [
    {
      id: 0,
      nombre: "Selecciona Un Prestador",
      value: 0,
    },
  ]

  export const defaultYear = [
    {
      id: "0",
      nombre: "Seleccione",
      value: "0",
    },
  ]

  export const defaultMonth = [
    {
      id: "0",
      nombre: "Seleccione",
      value: "0",
    },
  ]

  export const defaultContent = {
    isSuccess: false,
    content: "",
  }

  export const defaultTypeDocument = [
    { id: "33", nombre: "FACT. ELECT. AFECTA" },
    { id: "34", nombre: "FACT. ELECT. EXENTA" }
  ]

  export const defaultFormRegister = () => {
    let data = {
      date: new Date(),
      typeDocument: "33",
      net: "",
      exempt: "",
      tax: "",
      amount: "",
      folioDocument: 0,
      document: ""
    }
    return data;
  }

  export const tableHeaders = [
    'Tipo', 'Folio', 'Fecha Doc.', 'Monto Neto', 'Monto Exento', 'Monto Impuesto', 'Monto Total', 'Documento', 'Eliminar'
  ];

  export const tableHeadersTracking = [
    'Fecha solicitud', 'Total de solicitudes', 'Solicitadas', 'En Proceso', 'Rechazadas', 'Pagadas'
  ];


export const contextMedicalRendition = {
  idRendition: 0,
  invoices: [],
  totalMedicalBonds: 0,
  taxToPay: 0,
  netToPay: 0,
  amountToPay: 0,
  file: undefined,
  fileErrorBase64: '',
  holding: '',
  insertInvoices: [],  
  errorCount: 0,
  multiplesFacturas: true,
  diasCtrlCaducidad: 90,
  folioLiqImed: ''
}

export const contextMedicalRenditionTracking = {
  holding: "0",
  nameHolding: '',
  year: 0,
  month: 0,  
  medicalRenditions: [],
  totalRecords: 0,
  type: '0',
  dateRequest: new Date(),
  update: true
}

export const stateTrackingRendition = 
[{
    id: '5',
    nombre: 'SOLICITADAS',
    value: '5'
  },
  {
    id: '1',//INCLUYE ESTADO 6 = REVISADA
    nombre: 'EN PROCESO',
    value: '1'
  },
  {
    id: '3',
    nombre: 'RECHAZADAS',
    value: '3'
  },
  {
    id: '2',
    nombre: 'PAGADAS',
    value: '2'
  },
  // {
  //   id: '6',
  //   nombre: 'REVISADAS',
  //   value: '6'
  // },
]

export const defaultRenditionByDocTrib = { holding: "0", stateRendition: "0", dateInit: null, dateEnd: null, folioRendition: "", folioBono: "", folioTributary: "", numRendition: 0 }

export const typeDate = 
[{
    id: '0',
    nombre: 'TODOS',
    value: '0'
  },
  {
    id: '1',
    nombre: 'SOLICITADA',
    value: '1'
  },
  {
    id: '2',
    nombre: 'EN PROCESO',
    value: '2'
  },
  {
    id: '3',
    nombre: 'RECHAZADA',
    value: '3'
  },
  {
    id: '4',
    nombre: 'PAGADA',
    value: '4'
  }
]

export const headCells = [
  {
    id: '1',
    columnName: "id",
    numeric: false,
    disablePadding: true,
    label: 'Nº Rendición',
    sorted: true,
  },
  {
    id: '2',
    columnName: "canal",
    numeric: true,
    disablePadding: false,
    label: 'Canal origen',
    sorted: false,
  },
  {
    id: '3',
    columnName: "totaldocstrib",
    numeric: true,
    disablePadding: false,
    label: 'Total doc trib.',
    sorted: true,
  },
  {
    id: '4',
    columnName: "totalVouchers",
    numeric: true,
    disablePadding: false,
    label: 'Total bonos',
    sorted: true,
  },
  {
    id: '5',
    columnName: "totalAmount",
    numeric: true,
    disablePadding: false,
    label: 'Total facturado',
    sorted: true,
  },
  {
    id: '6',
    columnName: "dateState",
    numeric: true,
    disablePadding: false,
    label: 'Fecha de estado',
    sorted: true,
  },
  {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: 'Nota resolutiva',
      sorted: false,
    },
];

export const defaultRowsPerPage = 10;

export const IVA = 19;

export const perPageGlobal = [10, 20, 30, 50, 100];

export const headCellsReports = [
  {
    id: '1',
    columnName: "id",
    numeric: false,
    disablePadding: true,
    label: 'Nº Rendición',
    sorted: true,
  },{
    id: '2',
    columnName: "rutProvider",
    numeric: false,
    disablePadding: true,
    label: 'RUT facturador',
    sorted: true,
  },{
    id: '3',
    columnName: "renditionStatus",
    numeric: false,
    disablePadding: true,
    label: 'Estado',
    sorted: true,
  },{
    id: '4',
    columnName: "dateState",
    numeric: false,
    disablePadding: true,
    label: 'Fecha Estado',
    sorted: true,
  },{
    id: '5',
    columnName: "documentFolio",
    numeric: false,
    disablePadding: true,
    label: 'Folio Doc Cobro',
    sorted: true,
  },{
    id: '6',
    columnName: "documentAmount",
    numeric: false,
    disablePadding: true,
    label: 'Monto doc. cobro',
    sorted: true,
  },{
    id: '7',
    columnName: "totalRendition",
    numeric: false,
    disablePadding: true,
    label: 'Total facturado',
    sorted: true,
  },{
    id: '8',
    columnName: "edit",
    numeric: false,
    disablePadding: true,
    label: 'Descargar',
    sorted: false,
  },
];