import { Button } from '@mui/material'
import downloadIcon from "../../../assets/icons/exportGreen.svg";
import { GetReportRenditionsByTribDoc } from '../../../services/getReportRenditionsByTribDoc';
import { clientCCP } from '../../../config/apolloClientsCCP';
import { getFormattedDate } from '../../../utils/getFormatDate';
import { utils, writeFile } from 'xlsx'
import { useState } from 'react';
import { defaultContent } from '../../../utils/constDefault';
import Toast from '../../../components/ui-components/Toast/Toast';

interface ReportMedicalRenditionProps {
    disabled: boolean;
    holding: string;
    stateRendition: string;
    dateInit: Date | null;
    dateEnd: Date | null;
    folioRendition: string;
    folioBono: string;
    folioTributary: string;
    numRendition: number;
    searchT: string | null;
    orderBy: string;
    typeOrder: string;
}

export const ReportMedicalRendition: React.FC<ReportMedicalRenditionProps> = ({ disabled, holding, stateRendition, dateInit, dateEnd, folioBono, folioRendition, folioTributary, searchT, orderBy, typeOrder }) => {
    const [contentAlert, setContentAlert] = useState<any>(defaultContent);
    const [alert, setAlert] = useState<boolean>(false);
    
    const openToast = (
        isSuccess: boolean,
        content: string,
        typeAlert: string,
        styleAlert: object
      ) => {
        setContentAlert({ isSuccess, content, typeAlert, styleAlert });
        setAlert(true);
    };

    const handleExcelGenerate = async () => {
        try {
            const data: any = await handleRefreshRenditionsBonos(1, []);        
            if(!data) {
                openToast(
                    false,                
                    "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                    "top",
                    { marginTop: "100px" }
                );
            }else {
                // La columna "fechaProbablePago" se muestra únicamente si el rut del proveedor tiene activada la flag visibilidadFechaCompromiso
                const sessionData = sessionStorage.getItem("info-prestadores");
                const prestadoresArray = sessionData
                    ? JSON.parse(sessionData).prestadores
                    : [];
                // shouldShowExpectedDateByProvider tiene el siguiente formato: { "96770100-9": true, "77413290-2": true, "76436880-0": true }
                const shouldShowExpectedDateByProvider = prestadoresArray.reduce((acc: {[key: string]: boolean}, provider: any) => {
                    acc[provider.rutPrestador] = provider.visibilidadFechaCompromiso;
                    return acc;
                }, {}) as { [key: string]: boolean };
                const dataFilteredByProvider = data.map((item: any) => {
                    if (!shouldShowExpectedDateByProvider[item.rutFacturador]) {
                        return { ...item, fechaProbablePago: "" };
                    }
                    return item;
                });
                
                const book = utils.book_new();
                const sheet = utils.json_to_sheet(dataFilteredByProvider);
        
                const name = `ReporteBusquedaRendiciones_${getFormattedDate(new Date())}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Reporte Bonos');
                writeFile(book, name);
                openToast(
                    true,
                    "Descarga del reporte exitosa. Acceder al reporte en carpeta de Descargas de su sistema local.",
                    "top",
                    { marginTop: "100px" }
                );
            }
        }catch(error) {
            console.log(error)
            openToast(
                false,                
                "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                "top",
                { marginTop: "100px" }
            );
        }        
    }

    const handleRefreshRenditionsBonos = async (page: number, allData = []) => {
        const sessionData: any = sessionStorage.getItem("info-prestadores");
        const parsedData = JSON.parse(sessionData);
        const rutHoldings = parsedData.prestadores.map((prestador: { rutPrestadorHolding: any; }) => prestador.rutPrestadorHolding)[0];
        
        return new Promise(async (resolve, reject) => {
            try {
                const result = await clientCCP.query({
                    query: GetReportRenditionsByTribDoc,
                    variables: {
                        renditionFilters: {
                            renditionFolio: folioRendition === "" || folioRendition === "0" ? null : Number(folioRendition),
                            rutBilling: holding === "" || holding === "0" ? null : holding,
                            renditionStatus: stateRendition === "" || stateRendition === "0" ? null : stateRendition,
                            startDate: dateInit? getFormattedDate(dateInit): null,
                            endDate: dateEnd? getFormattedDate(dateEnd): null,
                            bonoFolio: folioBono === "" || folioBono === "0" ? null : Number(folioBono),
                            tributaryFolio: folioTributary === "" || folioTributary === "0" ? null : String(folioTributary),
                            searchText: (searchT) ? searchT: null,
                            rutHolding: rutHoldings
                        },
                        page: page,
                        perPage: 5000,
                        orderBy: `${orderBy} ${typeOrder}`,
                        isCCP: false
                    }
                });
                const { data } = result.data.rendByFiltersReport;
                if (data) {
                    const preData = data.map(({ __typename, ...rest }: any) => rest);                    
                    let newData = allData.concat(preData);
                    if (data.length === 5000) {
                        handleRefreshRenditionsBonos(page + 1, newData).then(resolve)
                    } else {
                        resolve(newData);
                    }
                } else {
                    resolve(data);
                }
            } catch (error) {
                reject(error)
            }
        })
    };

  return (
    <>
        <Toast
            openAlert={alert}
            setOpenAlert={setAlert}
            contentAlert={contentAlert}
        />
        <Button 
            data-testid="btn-reporte"
            variant="outlined" 
            disabled={disabled}
            onClick={handleExcelGenerate}
            startIcon={<img src={downloadIcon} alt='Descargar reporte' />}
            sx={{p:"15px 40px"}}>
                Reporte
        </Button>
    </>
    
  )
}
