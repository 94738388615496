import {Table, TableContainer, Paper, TablePagination, Box, Typography, Grid, Button } from "@mui/material";
import { useContext, useState } from "react";
import { NoResult } from "../ui-components/Table/noResult";
import { titleBox } from "../ui-components/Typography/typographyStyles";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";
import { defaultContent, perPageGlobal, tableHeadersTracking } from "../../utils/constDefault";
import { HeaderTable } from "../medicalRendition/headerTable";
import { BodyTableTracing } from "./bodyTableTracing";
import downloadIcon from "../../assets/icons/exportGreen.svg";
import { utils, writeFile } from "xlsx";
import { getFormattedDate } from "../../utils/getFormatDate";
import Toast from "../ui-components/Toast/Toast";
import { clientCCP } from "../../config/apolloClientsCCP";
import { getReportRenditionsTracking } from "../../services/getReportRenditionsTracking";

const TableMonitoring = ({setDetailVisible, getRenditions, page, setPage, rowsPerPage, setRowsPerPage, errorSummary, dataSearch}: any) => {
    const { dataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
    const { medicalRenditions, totalRecords } = dataContext;   
    const [openLoad, setOpenLoader] = useState(false);
    const [textLoad, setTextLoad] = useState('');
    const [contentAlert, setContentAlert] = useState<any>(defaultContent);
    const [alert, setAlert] = useState<boolean>(false);

    const handleChangePage = (_event: any, newPage: any) => {
        setPage(newPage)
        getRenditions(newPage, rowsPerPage)
    };

    const handleChangeRowsPerPage = (event: any) => {
        const perPage = parseInt(event.target.value, 10);
        setPage(0)
        setRowsPerPage(perPage)
        getRenditions(0, perPage)
    };

    const openToast = (
        isSuccess: boolean,
        content: string,
        typeAlert: string,
        styleAlert: object
      ) => {
        setContentAlert({ isSuccess, content, typeAlert, styleAlert });
        setAlert(true);
    };

    const handleExcelGenerate = async () => {
        try {
            const data: any = await obtReportTrackingInfo(1, 5000)          
            if(!data) {
                openToast(
                    false,                
                    "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                    "top",
                    { marginTop: "100px" }
                );
            }else { 
                const book = utils.book_new();
                const sheet = utils.json_to_sheet(data);
        
                const name = `ReporteSeguimiento_${getFormattedDate(new Date())}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Detalle reporte');
                writeFile(book, name);
                openToast(
                    true,
                    "Descarga del reporte exitosa. Acceder al reporte en carpeta de Descargas de su sistema local.",
                    "top",
                    { marginTop: "100px" }
                );
            }
        }catch(error) {
            console.log(error)
            openToast(
                false,                
                "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                "top",
                { marginTop: "100px" }
            );
        }        
    }

    const obtReportTrackingInfo = async (pageInit: number, perPage: number, allData = []) => {

        return new Promise(async (resolve, reject) => {

            try {
                setOpenLoader(true);
                setTextLoad("Buscando información...");
                const result = await clientCCP.query({
                    query: getReportRenditionsTracking,
                    variables: {
                        month: Number(dataSearch.month),
                        year: Number(dataSearch.year),
                        rutProvider: dataSearch.holding,
                        page: pageInit,
                        perPage: perPage
                    },
                });

                const { data } = result.data.rendByTrackingReport;
                if (data) {
                    const preData = data.map(({ __typename, ...rest }: any) => rest);
                    let newData = allData.concat(preData);
                    if (data.length == perPage) {
                        obtReportTrackingInfo(page + 1, perPage, newData).then(resolve)
                    } else {
                        resolve(newData);
                    }
                }
                setOpenLoader(false);
            } catch (error) {
                console.log(error)
                setOpenLoader(false);
            }
        })
    };

    return (
        <> 
        <Toast
            openAlert={alert}
            setOpenAlert={setAlert}
            contentAlert={contentAlert}
        />
            <Grid container p="1.5rem" borderTop={"1px solid #E9EBEC"} borderBottom={"1px solid #E9EBEC"}>
                <Grid item xs={9} pt="8px">
                    <Typography sx={titleBox} >Resumen seguimiento de rendiciones médicas</Typography>
                </Grid>
                <Grid item xs={3} textAlign={"start"} pl="1.7rem">
                    <Button
                        variant="outlined"
                        disabled={medicalRenditions.length > 0 ? false : true}
                        onClick={(handleExcelGenerate)}
                        startIcon={<img src={downloadIcon} alt='Descargar reporte' />}
                        sx={{ p: "15px 40px" }}>
                        Reporte
                    </Button>
                </Grid>
                </Grid> 
            {
                errorSummary === true ?
                <NoResult
                    newFound
                    titulo={"No se encontró información de rendiciones"}
                    subtitulo={"Inténtelo nuevamente usando otra información de filtro"} /> 
                :
                medicalRenditions.length === 0 ?            
                    <NoResult newDontSearch titulo={"Realiza una búsqueda"} subtitulo={"Ingresa información para visualizar seguimiento de rendiciones"} />
                :
                <Paper className="paper-tables">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                            <HeaderTable tableHeaders={ tableHeadersTracking } />
                            <BodyTableTracing setDetailVisible={setDetailVisible} />                            
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={perPageGlobal}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ mt: "14px", mb: "14px" }}
                        labelRowsPerPage={ `Mostrar por página`}
                        labelDisplayedRows={(e) => {
                          return (
                            "" +
                            (page + 1) +
                            " de " +
                            Math.ceil(totalRecords / rowsPerPage)
                          );
                        }}
                      />
                </Paper>
            }
        </>

        
    )
}

export default TableMonitoring;