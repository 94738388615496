/* statusData es usado en modal historial resolutivo.
 * Por requerimiento, servicio no entrega status 45 (liquidada).
 * Además, estados emitida (46 y 50) se muestran como "Liquidada" (únicamente en front) */
export const statusData = [
  { id: 40, nombre: "Entregada" },
  { id: 41, nombre: "En Proceso" },//Recepcionada
  { id: 42, nombre: "Visación" },
  { id: 43, nombre: "Valorización" },
  { id: 44, nombre: "Bonificación" },
  { id: 45, nombre: "Liquidada" },
  { id: 46, nombre: "Liquidada" },
  { id: 47, nombre: "Anulada" },
  { id: 48, nombre: "Rechazada" },
  { id: 49, nombre: "Devuelta" },
  { id: 50, nombre: "Liquidada Manual" },
  { id: 51, nombre: "Eliminada" },
];

export const statusDataGrouped = [
  { id: 1, nombre: "Entregada" },
  { id: 2, nombre: "En proceso" },
  { id: 4, nombre: "Rechazada" },
  { id: 3, nombre: "Devuelta" },
  { id: 5, nombre: "Liquidada" },
  { id: 6, nombre: "Liquidada Manual" },
  { id: 7, nombre: "Eliminada" },
  { id: 8, nombre: "Anulada" },
  { id: 0, nombre: "PENDIENTE MIGRAR NUEVO ESTADO" }
];

export const getStatusGroupedDescriptionById = (id: number): string => {
  const found = statusDataGrouped.find((status) => status.id === id);
  if (found) return found.nombre;
  return id.toString();
};

export enum statusEnumGrouped {
  ENTREGADA = 1,
  ENPROCESO = 2,
  RECHAZADA = 4,
  DEVUELTA = 3,
  LIQUIDADA = 5,
  LIQUIDADA_MANUAL = 6,
  ELIMINADA = 7,
  ANULADA = 8
}

export const getStatusDescriptionById = (id: number, isGrouped: boolean): string => {
  const found = (isGrouped ? statusDataGrouped : statusData).find((status) => status.id === id);
  if (found) return found.nombre;
  return id.toString();
};