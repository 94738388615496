import {
    Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Divider,
  Grid,
  TextField
} from "@mui/material";
import icon_warningOrange from "../../../assets/warningIcon.svg";
import { modalText, modalTitle } from "../../ui-components/Typography/typographyStyles";
import { CustomModal, CustomModalTitle } from "./CustomModal";
import { Typography } from '@mui/material';
import DownloadRed from "../../../assets/redDownload.svg";
import DownloadGray from "../../../assets/downloadgray.svg";
import UploadWhite from "../../../assets/uploadWhite.svg";
import { useContext, useState } from "react";
import ContextRendition, { ContextValue } from "../../../context/contextRendition";
export const ModalImed = ({
    onOpen,
    onClose,
    onClick,
    setBonusModal,
    bonusModal,
    setOpen,
    handleFolioLiqImed,
    openLoadImed,
    handleDownloadFile,
    errorState
}: 
    any) => {
    const { dataContext, setDataContext } = useContext<ContextValue>(ContextRendition);
    const { folioLiqImed, fileErrorBase64 } = dataContext;    

    const handleClose = () => {
        setBonusModal(0)
        setOpen(false)
    }

    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle id="warning" onClose={onClose} >
                <Typography sx={modalTitle} paddingTop="16px">
                    Cargar folio de liquidación Imed
                </Typography>
            </CustomModalTitle>
            <DialogContentText textAlign="center" pl="10%" pr="10%"  sx={modalText} >
                Aquí podrás cargar bonos Imed asociados a folio Liquidación
            </DialogContentText>
            <Divider />

            <Box sx={{ maxWidth: 700, margin: '10px 20px', textAlign: 'left', padding: 2, }}>
                <Typography variant="h6" gutterBottom>
                    <strong>Carga</strong> 
                </Typography>
                <Typography variant="body2" gutterBottom sx={modalText}>
                    Ingresa el folio liquidación para cargar los bonos asociados
                </Typography>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                        <Typography sx={{marginTop: '15px'}} variant="h6" gutterBottom>
                            <strong>Folio liquidación</strong> 
                        </Typography>
                        <TextField
                            placeholder="Ejem: 16100021-8"
                            variant="outlined"
                            fullWidth
                            value={folioLiqImed}
                            onChange={(e) => setDataContext(prevState => ({
                                ...prevState,
                                folioLiqImed: e.target.value.toUpperCase()
                            }))}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            onClick={handleFolioLiqImed}
                            variant="contained"
                            color="primary"
                            startIcon={ 
                            openLoadImed === true ?
                            <CircularProgress
                                color="primary"
                                thickness={5}
                                size="24px"
                                sx={{ marginRight: "10px" }}
                              />  
                              :
                              <img src={UploadWhite} alt="" /> 
                            }
                            fullWidth
                            sx={{ marginTop: '35px', height: '100%', backgroundColor: '#008080', '&:hover': { backgroundColor: '#006666' } }}
                            disabled={!folioLiqImed || openLoadImed}
                        >
                            Cargar
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3, textAlign: 'left' }}>
                    <Typography variant="subtitle1"><strong>Resultado de carga:</strong></Typography>
                    <Typography variant="body1" sx={{backgroundColor: '#e6f6f5', padding: '10px', borderRadius: '8px'}}>
                        Total de bonos: <strong>{bonusModal}</strong>
                    </Typography>
                </Box>
            </Box>
            
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                            <Button color="secondary" sx={{ marginLeft: "24px", }} onClick={handleClose} disabled={openLoadImed}>
                                Cerrar
                            </Button>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                color="secondary"
                                startIcon={<img src={fileErrorBase64 !== "" ? DownloadRed : DownloadGray} alt="" />}
                                sx={{ color: "#B71C1C !important" }}
                                role="button-descarga-error"
                                disabled={ fileErrorBase64 && errorState !== "" ? false : true }
                                onClick={handleDownloadFile}
                                >
                                    
                                Descargar errores{" "}
                                
                            </Button>
                        </Box>
        </CustomModal>
    );
};
