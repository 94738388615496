import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import { getStatusDescriptionById } from "./status";

export const exportRecords = (data) => {
  try {
    const records = [];
    const filename = "CuentasMedicas";
    records.push([
      "Fecha de Entrega",
      "Fecha de Liquidación",
      "Fecha de Ingreso",
      "Estado",
      "Fecha de Devolución",
      "Nº C. Prestador",
      "Nº Cuenta",
      "Nº C. Extranet",
      "Nº Envío",
      "Nº Cobro",
      "Rut Prestador",
      "Razón Social",
      "Canal",
      "Rut Beneficiario",
      "Nombre Beneficiario",
      "Total facturado",
      "Nota",
      "Usuario"
    ]);
    data.forEach((item) => {
      const nota = item.historial ? item.historial.nota : null;
      const usuario = item.historial ? item.historial.idUsuario : null;
      try {
        records.push([
          item.fechaRecepcion,
          item.fechaEmision,
          (item.canalOrigen === "CMD" ? (item.fechaIngreso ?? '') : ''),
          getStatusDescriptionById(item.estado, true),
          item.fechaDevuelta,
          item.numeroCuenta,
          item.id,
          item.idCMD,
          item.numeroEnvio,
          item.numeroCobro,
          item.rutPrestador,
          item.razonSocial,
          item.canalOrigen,
          item.rutPaciente,
          item.nombrePaciente,
          item.montoTotal,
          nota,
          usuario
        ]);
      } catch(err){
        console.log(item. id)
        throw err
      }

    });
    const excelCuentas = new Workbook();
    excelCuentas.creator = "CTAMD";
    excelCuentas.addWorksheet(filename);
    const libroCuentas = excelCuentas.getWorksheet(1);
    libroCuentas.addRows(records);
    excelCuentas.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const name = `${filename}_${format(new Date(), "ddMMyyyy")}.xlsx`;
      saveAs(blob, name);
      return true;
    });
    return true;
  } catch (ex) {
    console.error('Error al exportar', ex);
    return false;
  }
};
