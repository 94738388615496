import { gql } from "@apollo/client";

export const uploadFolioImed = gql`
mutation(   $folioLiqImed: String,
            $rutPrestador:String,
            $namePrestador:String,
            $rutHolding:String, 
            $nameHolding:String,
            $medicalRenditionId: Int, 
            $diasCtrlCaducidad: Int,
            $multiplesFacturas: Boolean, 
            $providerUser: String
    ){
        uploadFolioImed(
            folioLiqImed: $folioLiqImed
            rutPrestador:$rutPrestador,
            namePrestador: $namePrestador,
            rutHolding:$rutHolding,
            nameHolding: $nameHolding,
            medicalRenditionId:$medicalRenditionId,
            diasCtrlCaducidad:$diasCtrlCaducidad,
            multiplesFacturas: $multiplesFacturas,
            providerUser: $providerUser
        ){
            estado
            mensaje
            file
            errorCount
            data{
                idRendicion
                montoTotal
                bonosTotal
                facturas
            }
        }
    }
`;