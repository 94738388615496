import { createContext } from "react";

interface Invoice {
    id: string;
    nombre: string;
    value: string;
}

export interface ContextValue {
    dataContext: { 
        idRendition: number
        invoices: Invoice[]
        totalMedicalBonds: number
        taxToPay: number
        netToPay: number
        amountToPay: number
        file: File | undefined
        fileErrorBase64: string
        holding: string
        insertInvoices: any[]
        errorCount: number,
        multiplesFacturas: boolean,
        diasCtrlCaducidad: number,
        folioLiqImed: string
     };
     setDataContext: React.Dispatch<React.SetStateAction<ContextValue["dataContext"]>>;
  }
  
  const ContextRendition = createContext<ContextValue>({
    dataContext: {
        idRendition: 0,
        invoices: [],
        totalMedicalBonds: 0,
        taxToPay: 0,
        netToPay: 0,
        amountToPay: 0,
        file: undefined,
        fileErrorBase64: '',
        holding: '',
        insertInvoices: [],
        errorCount: 0,
        multiplesFacturas: false,
        diasCtrlCaducidad: 90,
        folioLiqImed: ''
    },
    setDataContext: () => {},
  });

export default ContextRendition;